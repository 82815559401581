@import url(https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap);
html {
  font-size: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  line-height: 1.3;
  background: whitesmoke;
}

.notFoundContainer {
  display: flex;
}
.notFoundContainer h1 {
  color: #00582e;
}
.notFoundContainer .notFoundTextContainer {
  margin-left: 55vw;
  margin-top: 13vh;
  font-size: 200%;
}
.notFoundContainer button {
  border: 2px solid #00582e;
}

@media only screen and (max-width: 630px) {
  .notFoundContainer {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
  }
  .notFoundContainer h1 {
    color: #00582e;
  }
  .notFoundContainer .notFoundTextContainer {
    margin-left: 0vw;
    margin-top: 0vh;
    font-size: 150%;
  }
  .notFoundContainer button {
    border: 2px solid #00582e;
  }
}

/*# sourceMappingURL=NotImplementedYet.css.map */

html {
  font-size: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  line-height: 1.3;
  background: whitesmoke;
}

.header {
  background: transparent;
  max-width: 100vw;
  min-height: 80px;
  max-height: -moz-fit-content;
  max-height: fit-content;
  margin: 0px;
  margin-top: 10px;
  min-width: 300px;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.languageImg {
  margin-right: 11px;
}

.logoImg {
  display: flex;
  margin-top: -160px;
  margin-bottom: 255px;
  align-items: center;
  margin-left: 40px;
}
.logoImg .logoHolder {
  display: flex;
}
.logoImg .logoHolder img {
  position: absolute;
  max-width: 150px;
}

.mobileLogoImg {
  display: none;
}

.logoImg:hover {
  filter: drop-shadow(0px 2px 2px black);
}

.navContainer {
  position: absolute;
  right: 20px;
  top: 10px;
}

.navholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10vh;
  gap: 10vh;
  padding-top: 7vh;
}
.navholder button {
  background: none;
  border: none;
  font-weight: 500;
  font-size: 140%;
  letter-spacing: 1.5px;
  color: #00582e;
  text-transform: uppercase;
}
.navholder button:active {
  color: #0eb967;
}

.languageImgDesktop {
  margin-right: 25px;
  filter: drop-shadow(0px 1.5px 1.5px black);
}

@media only screen and (min-width: 650px) {
  .navContainer {
    display: none;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .navContainerDesktop {
    position: fixed;
    left: 30vw;
    top: 0px;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    align-self: center;
    justify-content: center;
    width: 30vw;
    min-width: 250px;
    height: 100vh;
    background-color: white;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
  .contactIconsHolder {
    display: flex;
    align-items: center;
    color: white;
    grid-gap: 5px;
    gap: 5px;
    background: rgba(0, 88, 45, 0.733);
    padding: 4px;
    position: absolute;
    z-index: -1;
    left: 30vw;
    border-radius: 0px 8px 8px 0px;
    max-height: 40px;
    width: 50px;
  }
  .contactIconsHolder .phoneNumberDesktop {
    opacity: 0;
    width: 0;
    font-weight: 500;
  }
  .contactIconsHolder:hover {
    position: absolute;
    left: 30vw;
    border-radius: 0px 8px 8px 0px;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: rgba(0, 88, 45, 0.849);
    cursor: grab;
  }
  .contactIconsHolder:hover .phoneNumberDesktop {
    opacity: 1;
    width: 100%;
    transition: opacity 400ms ease-in, width 200ms ease-in-out;
  }
  button {
    border: none;
    background: none;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    display: inline-block;
    padding: 0.5em 0.8em;
    cursor: pointer;
    overflow: hidden;
    font-size: 105%;
    letter-spacing: 4px;
    margin-left: 40px;
    text-transform: uppercase;
    font-weight: 600;
    color: black;
  }
  button:before, button:after {
    content: "";
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: transparent;
  }
  button:before {
    top: 0;
  }
  button:after {
    bottom: 0;
  }
  button:hover > * > *:before, button:hover > * > *:after {
    transform: translate3d(0, 0, 0);
  }
  button:hover > * > * > *:before, button:hover > * > * > *:after {
    transform: translate3d(0, 0, 0);
  }
  button > *:before, button > *:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: transparent;
  }
  button > *:before {
    left: 0;
  }
  button > *:after {
    right: 0;
  }
  button > * > *:before, button > * > *:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 9;
    height: 2px;
    width: 100%;
    background-color: #157a49;
  }
  button > * > *:before {
    top: 0;
    transform: translate3d(-105%, 0, 0);
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }
  button > * > *:after {
    bottom: 0;
    transform: translate3d(105%, 0, 0);
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }
  button > * > * > *:before, button > * > * > *:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 9;
    height: 100%;
    width: 2px;
    background-color: #157a49;
  }
  button > * > * > *:before {
    left: 0;
    transform: translate3d(0, 105%, 0);
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }
  button > * > * > *:after {
    right: 0;
    transform: translate3d(0, -105%, 0);
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }
  button:hover {
    color: #157a49;
  }
  button:active {
    color: #0eb967;
  }
  .menuButton {
    display: block;
    margin: 10px;
    background: none;
    border: none;
    z-index: 1000;
    position: relative;
  }
  .menuImg {
    filter: drop-shadow(0px 2.5px 2.5px rgb(0, 0, 0));
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 650px) {
  .header {
    background: rgb(0, 88, 46);
    background: linear-gradient(90deg, rgb(0, 88, 46) 66%, rgb(21, 122, 73) 90%);
    box-shadow: 0px 3px 5px 0px #000000, 5px 5px 5px 5px rgba(0, 0, 0, 0);
    margin: 0px;
  }
  .contactIconsHolder {
    display: none;
  }
  .languageImgDesktop {
    display: none;
  }
  .menuImg {
    filter: drop-shadow(0px 2.5px 2.5px rgb(0, 0, 0));
    width: 30px;
    height: 30px;
  }
  .navContainerDesktop {
    display: none;
  }
  .mobileLogoImg {
    display: flex;
    position: absolute;
    left: 10px;
    top: 2px;
    width: 70px;
    max-width: 63px;
  }
  .mobileLogoImg img {
    max-width: 63px;
    filter: drop-shadow(0px 1.5px 1.5px black);
  }
  .mobileLogoImg:hover {
    filter: drop-shadow(0px 3px 3px rgb(255, 255, 255));
  }
}
@media only screen and (max-width: 1151px) {
  .navContainerDesktop button {
    text-align: left;
  }
}
@media only screen and (max-width: 850px) {
  .navContainerDesktop button {
    margin-left: 2vw;
  }
}
@media only screen and (max-width: 980px) {
  .navContainerDesktop button {
    font-size: 90%;
    text-align: left;
  }
}
@media only screen and (max-width: 830px) {
  .contactIconsHolder {
    display: none;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}

/*# sourceMappingURL=Header.css.map */

html {
  font-size: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  line-height: 1.3;
  background: whitesmoke;
}

.loginBox {
  display: flex;
  justify-content: center;
}
.loginBox .loginTitle {
  color: #00582e;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 120%;
}
.loginBox .loginForm {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  padding: 30px;
  grid-gap: 15px;
  gap: 15px;
  border: 1.5px solid #00582e;
  border-radius: 7px;
}
.loginBox .loginForm button {
  border: none;
  background: none;
  border: 2px solid #00582e;
  padding: 2px;
  font-size: 90%;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 600;
  color: #00582e;
}
.loginBox .loginForm button:hover {
  color: white;
  background: #157a49;
  border: 2px solid #00582e;
}
.loginBox .loginForm button:active {
  background: #0eb967;
}

/*# sourceMappingURL=Login.css.map */

html {
  font-size: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  line-height: 1.3;
  background: whitesmoke;
}

@media only screen and (min-width: 651px) {
  .landingContainer {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    grid-gap: 5vh;
    gap: 5vh;
    max-width: 70vw;
    margin-left: 30vw;
  }
  .landingContainer img {
    width: 20vw;
    margin-right: 70px;
    margin-left: 3vw;
    margin-top: 8vh;
    border-radius: 4px;
    box-shadow: 45px -35px 0px 1px rgba(0, 88, 45, 0.637);
  }
  .landingContainer img:hover {
    filter: grayscale(1) brightness(80%);
    transition: filter 1s ease-in-out;
  }
  .landingContainer .landingTextBox {
    font-family: "Oswald", sans-serif;
    margin-left: 6vw;
    margin-top: 7vh;
    margin-right: 1vw;
    grid-gap: 5vh;
    gap: 5vh;
    font-weight: 500;
    width: 90vw;
    text-align: left;
    font-size: 150%;
    color: black;
  }
  .landingContainer .landingTextBox .landingText {
    display: flex;
    flex-direction: column;
  }
  .landingContainer .landingTextBox button {
    margin-top: 3vh;
    margin-left: 0px;
    background: rgba(0, 88, 45, 0.808);
    color: white;
    font-weight: 300;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    font-size: 80%;
  }
  .landingContainer .landingTextBox button:hover {
    background: rgba(0, 88, 45, 0.993);
  }
  .landingContainer .landingTextBox button:active {
    background: rgb(4, 78, 42);
  }
  .landingContainer .landingTextBox .landingTitle {
    text-shadow: 1px 1px 0px #000000;
    font-family: "Oswald", sans-serif;
    text-align: left;
    font-size: 240%;
    font-weight: 500;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-bottom: 6vh;
    margin-top: 13vh;
  }
  .landingContainer .landingTextBox .landingTitleFirst {
    color: #00582e;
    font-weight: 700;
    font-size: 140%;
  }
  .landingContainer .landingTextBox .landingText {
    font-size: 80%;
  }
}
.landingpage {
  max-width: 70vw;
  margin-left: 20vw;
  display: flex;
  justify-content: center;
}

.landingContainer2 {
  display: flex;
  max-width: 70vw;
  margin-left: 30vw;
}
.landingContainer2 .landingText2 {
  position: absolute;
  margin-left: 6vw;
  margin-top: 46.5vh;
  width: 400px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 130%;
}

.landingImgContainer2 {
  display: flex;
  flex-direction: row;
  max-width: 40vw;
  margin-top: 3vh;
  margin-left: 30vw;
}
.landingImgContainer2 .gardenImg {
  position: absolute;
  left: 64vw;
  top: 146vh;
  box-shadow: 30px 30px 0px 1px rgba(0, 88, 45, 0.637);
}
.landingImgContainer2 .gardenImg2 {
  position: relative;
  left: -12vw;
  box-shadow: 30px 30px 0px 1px rgba(0, 88, 45, 0.637);
}
.landingImgContainer2 img:hover {
  filter: grayscale(1) brightness(80%);
  transition: filter 1s ease-in-out;
}

.landingContainer3 {
  display: flex;
  max-width: 70vw;
  margin-left: 30vw;
  margin-top: 2.9vh;
  justify-content: center;
}
.landingContainer3 .landingText3 {
  position: absolute;
  margin-left: 33vw;
  margin-top: 42.5vh;
  width: 400px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 130%;
}
.landingContainer3 .landingText3:hover {
  color: #3d3d3d;
}
.landingContainer3 .landingImgContainer3 {
  display: flex;
  grid-gap: 35px;
  gap: 35px;
}
.landingContainer3 .landingImgContainer3 img {
  box-shadow: 10px -20px 0px 1px rgba(0, 88, 45, 0.637);
}
.landingContainer3 .landingImgContainer3 img:hover {
  filter: grayscale(1) brightness(80%);
  transition: filter 1s ease-in-out;
}

.mapholder2 {
  position: relative;
  left: 35.5vw;
  top: 1vw;
  max-width: -moz-fit-content;
  max-width: fit-content;
  box-shadow: 0px 0px 6px 1px rgba(0, 88, 45, 0.637);
}

@media only screen and (max-width: 650px) {
  .mapholder2 {
    display: none;
  }
  .landingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 99vw;
    grid-gap: 3vh;
    gap: 3vh;
    max-width: 99vw;
  }
  .landingContainer img {
    width: 50vw;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-bottom: 15vh;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.7);
  }
  .landingContainer img:hover {
    filter: grayscale(1) brightness(80%);
    transition: filter 1s ease-in-out;
  }
  .landingContainer button {
    display: none;
  }
  .landingContainer .landingTextBox {
    margin-left: 3vw;
    margin-right: 3vw;
    grid-gap: 5vh;
    gap: 5vh;
    font-weight: 500;
    width: 90vw;
    text-align: center;
    font-size: 80%;
    color: #00582e;
    font-family: "Oswald", sans-serif;
  }
  .landingContainer .landingTextBox .landingTitle {
    text-shadow: 1px 1px 0px #000000;
    font-family: "Oswald", sans-serif;
    text-align: center;
    font-size: 190%;
    font-weight: 700;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    margin-bottom: 3vh;
    margin-top: 3vh;
  }
  .landingContainer2 {
    display: flex;
    max-width: 100vw;
    margin-left: 2vw;
    margin-top: 20vh;
  }
  .landingContainer2 .landingText2 {
    position: absolute;
    margin-left: 2vw;
    margin-right: auto;
    margin-top: 18.5vh;
    width: 90vw;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    font-size: 120%;
  }
  .landingImgContainer2 {
    display: flex;
    flex-direction: row;
    max-width: 40vw;
    margin-top: 3vh;
    margin-left: 30vw;
  }
  .landingImgContainer2 .gardenImg {
    position: absolute;
    transform: scale(0.5);
    left: 5vw;
    top: 110vh;
    box-shadow: 30px 30px 0px 1px rgba(0, 88, 45, 0.637);
  }
  .landingImgContainer2 .gardenImg2 {
    transform: scale(0.5);
    position: absolute;
    left: -15vw;
    top: 97vh;
    box-shadow: 30px 30px 0px 1px rgba(0, 88, 45, 0.637);
  }
  .landingImgContainer2 img:hover {
    filter: grayscale(1) brightness(80%);
    transition: filter 1s ease-in-out;
  }
  .landingContainer3 {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    margin-top: 35vh;
    margin-left: 0vw;
    justify-content: center;
  }
  .landingContainer3 .landingText3 {
    position: absolute;
    margin-top: 14.5vh;
    width: 90vw;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    font-size: 100%;
    margin-left: 6vw;
  }
  .landingContainer3 .landingImgContainer3 {
    width: 100vw;
    display: flex;
    margin-top: -70vh;
    flex-direction: column;
    grid-gap: 0px;
    gap: 0px;
  }
  .landingContainer3 .landingImgContainer3 img {
    padding: 0px;
    margin: 0px;
    transform: scale(0.8);
    box-shadow: 10px -20px 0px 1px rgba(0, 88, 45, 0.637);
  }
  .landingContainer3 .landingImgContainer3 img:hover {
    filter: grayscale(1) brightness(80%);
    transition: filter 1s ease-in-out;
  }
}

/*# sourceMappingURL=LandingPage.css.map */

html {
  font-size: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  line-height: 1.3;
  background: whitesmoke;
}

.galleryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7vh;
  grid-gap: 17px;
  gap: 17px;
}

.smallContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 17px;
  gap: 17px;
}

.galleryContainer2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 15px;
  grid-gap: 17px;
  gap: 17px;
}

.smallContainer2 {
  display: flex;
  flex-direction: column;
  grid-gap: 17px;
  gap: 17px;
}

.imgContainer img {
  max-width: 80vw;
}

.imgContainer:hover {
  filter: none;
}

.imgContainer:active {
  filter: none;
}

.lowerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 12vh;
  grid-gap: 17px;
  gap: 17px;
}

.smallContainer2 {
  display: flex;
  flex-direction: column;
  grid-gap: 17px;
  gap: 17px;
}

.imgContainer img {
  max-width: 80vw;
}

@media only screen and (min-width: 800px) {
  .galleryContainer {
    margin: 10px;
    margin-right: 25px;
    width: 60vw;
    margin-left: 35vw;
    display: grid;
    grid-template-columns: 49.5% 47.9%;
    grid-template-rows: auto;
    grid-gap: 10px;
    gap: 10px;
    grid-template-areas: "bigImg smallImgContainer";
  }
  .bigImg {
    display: flex;
    grid-area: bigImg;
  }
  .bigImg img {
    transition: all 0.7s ease-in-out;
  }
  .bigImg img:hover {
    border-radius: 15px;
  }
  .smallContainer {
    grid-area: smallImgContainer;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-gap: 15px 15px;
    gap: 15px 15px;
    grid-template-areas: "smallImg smallImg2" "smallImg3 smallImg4";
  }
  .smallImg {
    display: flex;
    grid-area: smallImg;
  }
  .smallImg img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg img:hover {
    border-radius: 15px;
  }
  .smallImg2 {
    display: flex;
    grid-area: smallImg2;
  }
  .smallImg2 img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg2 img:hover {
    border-radius: 15px;
  }
  .smallImg3 {
    display: flex;
    grid-area: smallImg3;
  }
  .smallImg3 img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg3 img:hover {
    border-radius: 15px;
  }
  .smallImg4 {
    display: flex;
    grid-area: smallImg4;
  }
  .smallImg4 img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg4 img:hover {
    border-radius: 15px;
  }
  .galleryContainer2 {
    margin-top: 0px;
    width: 60vw;
    margin-left: 35vw;
    display: grid;
    grid-template-columns: 47.2% 48.7%;
    grid-template-rows: auto;
    grid-gap: 25px;
    gap: 25px;
    grid-template-areas: "smallImgContainer2 bigImg2";
  }
  .bigImg2 {
    margin-right: -15px;
    display: flex;
    grid-area: bigImg2;
  }
  .bigImg2 img {
    transition: all 0.7s ease-in-out;
  }
  .bigImg2 img:hover {
    border-radius: 15px;
  }
  .smallContainer2 {
    grid-area: smallImgContainer2;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-gap: 15px 15px;
    gap: 15px 15px;
    grid-template-areas: "smallImg5 smallImg6" "smallImg7 smallImg8";
  }
  .smallImg5 {
    display: flex;
    grid-area: smallImg5;
  }
  .smallImg5 img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg5 img:hover {
    border-radius: 15px;
  }
  .smallImg6 {
    display: flex;
    grid-area: smallImg6;
  }
  .smallImg6 img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg6 img:hover {
    border-radius: 15px;
  }
  .smallImg7 {
    display: flex;
    grid-area: smallImg7;
  }
  .smallImg7 img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg7 img:hover {
    border-radius: 15px;
  }
  .smallImg8 {
    display: flex;
    grid-area: smallImg8;
  }
  .smallImg8 img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg8 img:hover {
    border-radius: 15px;
  }
  .smallImg5 {
    display: flex;
    grid-area: smallImg5;
  }
  .smallImg5 img {
    transition: all 0.7s ease-in-out;
  }
  .smallImg5 img:hover {
    border-radius: 15px;
  }
  .lowerContainer {
    margin: 0px;
    margin-left: 25px;
    margin-right: 25px;
    width: 60vw;
    margin-left: 35vw;
    display: grid;
    grid-template-columns: 24.35% 24.35% 24.35% 24.35%;
    grid-template-rows: auto;
    grid-gap: 8.2px;
    gap: 8.2px;
    margin-bottom: 12vh;
  }
  .lowerContainer img {
    transition: all 0.7s ease-in-out;
  }
  .lowerContainer img:hover {
    border-radius: 15px;
  }
}

/*# sourceMappingURL=MyGallery.css.map */

html {
  font-size: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  line-height: 1.3;
  background: whitesmoke;
}

.messageTitle {
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 12px;
  font-weight: 700;
  color: #00582e;
}

.messageForm {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-width: 300px;
  margin-top: 10%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  grid-gap: 10px;
  gap: 10px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  background: rgba(36, 56, 36, 0.158);
}
.messageForm input {
  padding: 7px;
  border: none;
  color: #00582e;
  font-size: 110%;
  background: white;
  font-weight: 550;
}
.messageForm input:focus {
  border: 2px solid #0eb967;
}
.messageForm .textBoxInput {
  padding-bottom: 40%;
}
.messageForm button {
  margin-top: 20px;
  background: #00582e;
  color: white;
  text-transform: uppercase;
  font-weight: 550;
  border: none;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 130%;
  width: -moz-fit-content;
  width: fit-content;
  align-self: flex-end;
  padding: 10px;
}
.messageForm button:hover {
  background: #157a49;
}
.messageForm button:active {
  background: #0eb967;
}

.contactFormButton {
  background: whitesmoke;
  color: #00582e;
  text-transform: uppercase;
  font-weight: 550;
  border: none;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 130%;
}

@media only screen and (min-width: 800px) {
  .messageBox {
    display: flex;
    justify-content: space-between;
    width: 69.5vw;
    margin-left: 30vw;
  }
  .messageTitle {
    font-size: 15px;
  }
  .messageForm {
    width: 40%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0px;
    margin-top: 15vh;
    margin-right: 2vw;
    margin-bottom: 0vh;
    padding: 10px;
  }
  .messageForm button {
    font-size: 70%;
  }
  .textBoxInput {
    padding-bottom: 0px;
    margin-bottom: -20px;
  }
  .contactInfoContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    margin-right: auto;
    margin-left: auto;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 100%;
  }
  .contactInfoContainer .contactInfoTitle {
    font-size: 170%;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #00582e;
  }
  .contactInfoContainer .contactUnderTitle {
    font-size: 72%;
    color: #00582e;
  }
  .contactInfoContainer .contactEmail {
    margin-top: 4%;
    display: flex;
    align-items: center;
    margin-left: 10%;
    grid-gap: 15px;
    gap: 15px;
    color: #00582e;
    font-weight: 400;
  }
  .contactInfoContainer .contactPhoneNumber {
    display: flex;
    align-items: center;
    margin-left: 10%;
    grid-gap: 15px;
    gap: 15px;
    color: #00582e;
    font-weight: 400;
  }
  .contactInfoContainer .contactAddress {
    display: flex;
    align-items: center;
    margin-left: 10%;
    grid-gap: 15px;
    gap: 15px;
    color: #00582e;
    font-weight: 400;
  }
  .contactInfoContainer .mapholder {
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 1095px) {
  .messageBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30vw;
  }
  .messageTitle {
    font-size: 15px;
  }
  .messageForm {
    width: 40%;
    margin: 0px;
    margin-top: 10vh;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20vh;
    padding: 30px;
  }
  .textBoxInput {
    padding-bottom: 0px;
  }
  .contactInfoContainer {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    margin-right: 3vw;
    margin-left: 3vw;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 110%;
  }
  .contactInfoContainer .contactInfoTitle {
    align-self: center;
    font-size: 170%;
    margin-left: 6vw;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #00582e;
  }
  .contactInfoContainer .contactUnderTitle {
    margin-left: 6vw;
    margin-top: 7px;
    font-size: 80%;
    color: #00582e;
  }
  .contactInfoContainer .contactEmail {
    margin-top: 4%;
    display: flex;
    align-items: center;
    margin-left: 10%;
    grid-gap: 15px;
    gap: 15px;
    color: #00582e;
    font-weight: 500;
    font-size: 90%;
  }
  .contactInfoContainer .contactPhoneNumber {
    display: flex;
    align-items: center;
    margin-left: 10%;
    grid-gap: 15px;
    gap: 15px;
    color: #00582e;
    font-weight: 500;
    font-size: 90%;
  }
  .contactInfoContainer .contactAddress {
    display: flex;
    align-items: center;
    margin-left: 10%;
    grid-gap: 15px;
    gap: 15px;
    color: #00582e;
    font-weight: 500;
    font-size: 90%;
  }
  .contactInfoContainer .mapholder {
    margin-top: 8%;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 635px) {
  .messageBox {
    margin-left: auto;
  }
}

/*# sourceMappingURL=ContactUs.css.map */

@media (min-width: 618px) {
    .custom-container {
        margin-left: 45vw;
        margin-right: 15vw;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .card-body .button-container {
        display: flex;
        justify-content: flex-end;
    }

    .card-header {
        background-color: #026431;
        color: white; 
        padding: 10px; 
        border-radius: 5px 5px 0 0; 
}

}

@media (max-width: 617px) {
    .custom-container {
        margin-left: 3vw;
        margin-right: 3vw;
        margin-top: 3vh;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .card-body .button-container {
        display: flex;
        justify-content: flex-end;
    }

    .card-header {
        background-color: #026431; 
        color: white; 
        padding: 10px; 
        border-radius: 5px 5px 0 0;
    }
}
html {
  font-size: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  line-height: 1.3;
  background: whitesmoke;
}

.footerContainer {
  display: flex;
  width: 100vw;
  min-width: 250px;
  height: 11vh;
  background: rgba(10, 119, 66, 0.6980392157);
  position: fixed;
  left: 30vw;
  bottom: 0px;
  align-items: center;
  padding-left: 10px;
  text-shadow: 1px 1px 0px #000000;
}

.footerContainer:active {
  background: rgba(10, 119, 66, 0.9333333333);
}

.footerIconHolder {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  margin-left: 3vw;
  filter: drop-shadow(0px 1.5px 1.5px black);
}

.footerIconHolder:hover {
  filter: drop-shadow(0px 3px 3px black);
}

.footerText {
  color: white;
  font-weight: 600;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  font-size: 2.5vw;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

.footerBalaton {
  padding-right: 5%;
  filter: drop-shadow(0px 1.5px 1.5px black);
}

.footerBalaton:hover {
  filter: drop-shadow(0px 3px 3px black);
}

@media only screen and (min-width: 650px) {
  .footerContainer {
    max-width: 30vw;
  }
  .footerText {
    font-size: 0.66vw;
  }
  .footerIconHolder {
    margin-left: 1.5vw;
  }
  .footerBalaton {
    padding-right: 0%;
    margin-right: 1.5vw;
  }
}
@media only screen and (max-width: 982px) {
  .footerBalaton {
    margin-left: -10px;
  }
}
@keyframes puff-in-center {
  0% {
    transform: scale(2);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    filter: blur(0px);
    opacity: 1;
  }
}

/*# sourceMappingURL=Footer.css.map */

.loaderContainer {
  margin-left: 63vw;
  margin-top: 40vh;
}

@media only screen and (max-width: 650px) {
  .loaderContainer {
    margin-left: 50%;
    margin-right: 50%;
  }
}

/*# sourceMappingURL=Loader.css.map */

html {
  font-size: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  line-height: 1.3;
  background: whitesmoke;
}

html,
body {
  font-family: "Titillium Web", sans-serif;
}

/*# sourceMappingURL=App.css.map */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

