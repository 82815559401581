@media (min-width: 618px) {
    .custom-container {
        margin-left: 45vw;
        margin-right: 15vw;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .card-body .button-container {
        display: flex;
        justify-content: flex-end;
    }

    .card-header {
        background-color: #026431;
        color: white; 
        padding: 10px; 
        border-radius: 5px 5px 0 0; 
}

}

@media (max-width: 617px) {
    .custom-container {
        margin-left: 3vw;
        margin-right: 3vw;
        margin-top: 3vh;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .card-body .button-container {
        display: flex;
        justify-content: flex-end;
    }

    .card-header {
        background-color: #026431; 
        color: white; 
        padding: 10px; 
        border-radius: 5px 5px 0 0;
    }
}